const LOCAL_STORAGE_ID = 'preferencias'

class Preferencias {
  default = {
    gate: null,
    tipo_operacao: null,
    fornecedor: null,
    tipo_frete: null,
    terminal: null,
    produto: null,
    sub_produto: null,
    regiao: [],
  }
  constructor() {
    this.storage_id = LOCAL_STORAGE_ID
    this._data =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || this.default
  }
  getLocalStorage() {
    return JSON.parse(localStorage.getItem(this.storage_id))
  }
  get data() {
    if (!this._data) {
      this._data = this.getLocalStorage()
    }
    return this._data
  }
  set data(data) {
    this.data = data
    this.save()
  }
  clear() {
    localStorage.setItem(this.storage_id, JSON.stringify([]))
  }
  save() {
    localStorage.setItem(this.storage_id, JSON.stringify(this.data))
  }
  set(attr, paylod) {
    this.data[attr] = paylod
    this.save()
  }
  get gate() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.gate
  }
  set gate(gate) {
    this.data.gate = gate
    this.save()
  }

  get tipo_operacao() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.tipo_operacao
  }
  set tipo_operacao(tipo_operacao) {
    this.data.tipo_operacao = tipo_operacao
    this.save()
  }

  get fornecedor() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.fornecedor
  }
  set fornecedor(fornecedor) {
    this.data.fornecedor = fornecedor
    this.save()
  }

  get tipo_frete() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.tipo_frete
  }
  set tipo_frete(tipo_frete) {
    this.data.tipo_frete = tipo_frete
    this.save()
  }

  get regiao() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.regiao
  }
  set regiao(regiao) {
    this.data.regiao = regiao
    this.save()
  }

  get terminal() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.terminal
  }
  set terminal(terminal) {
    this.data.terminal = terminal
    this.save()
  }

  get produto() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.produto
  }
  set produto(produto) {
    this.data.produto = produto
    this.save()
  }


  get sub_produto() {
    if (!this.data) {
      this.data = this.getLocalStorage()
    }
    return this.data.sub_produto
  }
  set sub_produto(sub_produto) {
    this.data.sub_produto = sub_produto
    this.save()
  }

}

export { Preferencias, LOCAL_STORAGE_ID }
