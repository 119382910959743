var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{attrs:{"href":"#meusgrupos"}},[_vm._v("Meus Grupos")]),(_vm.empresaAtual.system_admin)?_c('v-tab',{attrs:{"href":"#todososgrupos"}},[_vm._v("Todos os Grupos")]):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{attrs:{"value":"meusgrupos"}},[_c('div',{staticClass:"mt-4"},[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.grupos,"search":_vm.search,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Grupos registrados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"title":"Atualizar","medium":""},on:{"click":function () { return _vm.getGrupos(); }}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canCreate)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Novo Grupo")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('cadastro-grupo',{attrs:{"formTitle":_vm.formTitle},on:{"close":_vm.close}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)}),(_vm.canDelete)?_c('confirm-dialog',{on:{"confirm":_vm.confirmDelete}}):_vm._e()],1)]),_c('v-tab-item',{attrs:{"value":"todososgrupos"}},[_c('div',{staticClass:"mt-4"},[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headersAllGrupos,"items":_vm.todosGrupos,"search":_vm.search,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Grupos registrados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"title":"Atualizar","medium":""},on:{"click":_vm.getAllGrupos}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canCreate)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Novo Grupo")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('cadastro-grupo',{attrs:{"formTitle":_vm.formTitle},on:{"close":_vm.close}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e(),_c('confirm-dialog',{on:{"confirm":_vm.confirmDelete}})]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }