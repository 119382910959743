<template>
  <div>
    <v-row>
      <v-col class="6" sm="6" lg="6">
        <v-autocomplete
          v-if="empresaAtual.gate"
          label="Fornecedor"
          v-model="fornecedor"
          item-text="business_name"
          item-value="public_id"
          return-object
          :items="fornecedores"
          :loading="loading"
          @change="handleFornecedorSelected"
        />
      </v-col>
    </v-row>
    <v-row class="text-start">
      <v-col class="12" sm="12" lg="12">
        <div class="text-center">
          <span>Terminais</span>
        </div>
        <v-treeview
          v-model="currentItems"
          selectable
          open-on-click
          transition
          dense
          item-text="name"
          item-key="public_id"
          return-object
          :items="terminais"
        />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <buttons-cadastro @close="close" @save="atualizarTerminais" />
    </v-card-actions>
  </div>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  inject: {
    atualizaGrupo: {
      default: () => {},
    },
  },
  props: {
    grupo: Object,
  },
  components: { ButtonsCadastro },
  data() {
    return {
      loading: false,
      fornecedor: null,
      currentItems: [],
      originalItems: [],
    }
  },
  async created() {
    this.currentItems = this.grupo.config.terminais || []
    this.originalItems = this.grupo.config.terminais || []
    if (this.empresaAtual.gate) {
      this.loading = true
      await this.getFornecedores()
      this.SET_TERMINAIS_FORNECEDORES([])
      this.loading = false
    } else {
      await this.buscarTerminaisFornecedor()
    }
  },
  computed: {
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('contratos', ['terminaisFornecedores']),
    ...mapState('auth', ['empresaAtual']),
    terminais() {
      if (!this.terminaisFornecedores) {
        return []
      }
      return this.terminaisFornecedores.map((item) => {
        return {
          ...item,
          name: `${item.business_name} - ${item.cnpj}`,
        }
      })
    },
  },
  methods: {
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapActions('contratos', [
      'atualizarParceria',
      'buscarTerminaisFornecedores',
      'buscarTerminaisFornecedor',
    ]),
    ...mapActions('gerencial', ['updateGrupo']),
    ...mapActions('fornecedores', [
      'getFornecedores',
      'getFornecedorByPublicID',
      'getFornecedoresByParams',
    ]),
    ...mapMutations('contratos', ['SET_TERMINAIS_FORNECEDORES']),
    close() {
      this.$emit('cancelarEdicao')
    },
    reset() {
      this.currentItems = this.originalItems
    },
    async handleFornecedorSelected() {
      await this.buscarTerminaisFornecedor({
        public_id: this.fornecedor.fornecedor_id,
      })
      this.$nextTick(() => {
        this.currentItems = []
        const ids =
          'terminais' in this.grupo.config ? this.grupo.config.terminais : []
        this.terminaisFornecedores.forEach((element) => {
          if (ids.includes(element.public_id)) {
            this.currentItems.push(element)
          }
        })
      })
    },
    async atualizarTerminais() {
      try {
        const grupo = await this.updateGrupo({
          grupoPublicId: this.grupo.public_id,
          payload: {
            terminais: this.currentItems.map((s) => s.public_id),
            fornecedor: this.fornecedor.public_id,
          },
        })
        this.atualizaGrupo(grupo)
        this.successMessage('Terminais atualizados com sucesso')
      } catch {
        this.errorMessage('Erro ao atualizar terminais')
      }
    },
  },
}
</script>
