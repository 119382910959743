import { Preferencias } from '@/utils/preferencias'

export const preferencias = {
  data() {
    return {
      preferencias: null,
    }
  },
  created() {
    this.preferencias = new Preferencias()
  },
}
