<template>
  <div>
    <v-row>
      <v-col class="6" sm="6" lg="6">
        <v-autocomplete
          v-if="empresaAtual.gate"
          label="Fornecedor"
          v-model="fornecedor"
          item-text="business_name"
          item-value="public_id"
          return-object
          :items="fornecedores"
          :loading="loading"
          @change="handleFornecedorSelected"
        />
        <v-autocomplete
          v-else
          label="Gate"
          v-model="gate"
          item-text="business_name"
          item-value="public_id"
          return-object
          :items="gates"
          :loading="loading"
          @change="handleGetFornecedor"
        />
      </v-col>
    </v-row>
    <v-row class="text-start">
      <v-col class="6" sm="6" lg="6">
        <div class="text-center">
          <span>SubProdutos</span>
        </div>
        <v-treeview
          v-model="sub_produtos"
          selectable
          open-on-click
          transition
          dense
          item-text="descricao"
          item-key="key"
          item-children="subprodutos"
          return-object
          :items="produtos"
        />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <buttons-cadastro @close="close" @save="atualizarProdutos" />
    </v-card-actions>
  </div>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { mapActions, mapState } from 'vuex'
import ContratoAPI from '@/services/tenants/contratos'
import GrupoApi from '@/services/gerencial/grupo'
import { preferencias } from '@/mixins/preferencias'

export default {
  inject: {
    atualizaGrupo: {
      default: () => {},
    },
  },
  props: {
    grupo: Object,
  },
  components: { ButtonsCadastro },
  mixins: [preferencias],

  data() {
    return {
      loading: false,
      gate: {},
      gates: [],
      sub_produtos: [],
      fornecedor: {},
    }
  },
  async created() {
    if (this.empresaAtual.gate) {
      if (this.isEdit) {
        this.sub_produtos = []
      } else {
        this.forms = this.getDefaultForms()
        this.fornecedor = {
          produtos: [],
        }
      }
      this.loading = true
      await this.getFornecedores()
      this.loading = false
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates

      if (this.preferencias.gate?.public_id) {
        this.gate.public_id = this.preferencias.gate.public_id
        this.handleGetFornecedor(this.gate)
      }

      Object.assign(this.sub_produtos, {
        ...this.grupo.sub_produtos,
      })
    }
  },
  computed: {
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
    isEdit() {
      return this.grupo.sub_produtos !== undefined
    },
    produtos() {
      if (!this.fornecedor) {
        return []
      }
      if (!Object.keys(this.fornecedor).includes('produtos')) {
        return []
      }
      let key = 0
      const getKey = () => {
        key += 1
        return key
      }
      return this.fornecedor.produtos.map((prod) => {
        return {
          key: getKey(),
          ...prod,
          subprodutos: prod.subprodutos.map((subprod) => {
            return {
              key: getKey(),
              ...subprod,
            }
          }),
        }
      })
    },
  },
  methods: {
    ...mapActions('fornecedores', [
      'getFornecedores',
      'getFornecedorByPublicID',
      'getFornecedoresByParams',
    ]),
    close() {
      this.$emit('cancelarEdicao')
    },

    async handleFornecedorSelected() {
      this.$nextTick(() => {
        this.sub_produtos = []
        const ids = this.grupo.sub_produtos.map((item) => item.id)
        this.produtos.forEach((element) => {
          element.subprodutos.forEach((item) => {
            if (ids.includes(item.id)) {
              this.sub_produtos.push(item)
            }
          })
        })
      })
    },
    async handleGetFornecedor(gate) {
      const data = await this.getFornecedoresByParams({
        params: {
          fornecedor: this.empresaAtual.public_id,
        },
        empresa: gate,
      })
      this.fornecedor = data[0]

      if (this.preferencias) {
        this.preferencias.gate = this.gate
      }
    },
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapActions('gerencial', ['updateGrupo']),
    async reset() {
      console.log('vxvxvx')
    },
    async atualizarProdutos() {
      try {
        const grupo = await GrupoApi.setSubProduto(
          this.grupo.public_id,
          this.sub_produtos.map((s) => s.id),
          this.fornecedor.public_id
        )
        this.atualizaGrupo(grupo)
        this.successMessage('Produtos atualizados com sucesso')
      } catch {
        this.errorMessage('Erro ao atualizar produtos')
      }
    },
  },
}
</script>
