<template>
  <div class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados"> Dados gerais <v-icon>mdi-home-city</v-icon></v-tab>
      <v-tab href="#permissions">
        Permissões Básicas <v-icon>mdi-security</v-icon></v-tab
      >
      <v-tab href="#terminais"> Terminais <v-icon>mdi-widgets</v-icon></v-tab>
      <v-tab href="#produto"> Produtos <v-icon>mdi-barley</v-icon></v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-grupo
            v-model="grupo"
            :grupoNome="grupo.nome"
            @cancelarEdicao="$emit('cancelarEdicao')"
          />
        </v-tab-item>
        <v-tab-item value="permissions">
          <items-menu
            @cancelarEdicao="$emit('cancelarEdicao')"
            :itemsSelected="grupo.menu"
            :grupoPublicId="grupo.public_id"
            :basicPermissions="grupo.basic_permissions"
          />
        </v-tab-item>
        <v-tab-item value="terminais" eager>
          <list-terminal
            class="pa-4"
            :grupo="grupo"
            @cancelarEdicao="$emit('cancelarEdicao')"
          />
        </v-tab-item>
        <v-tab-item value="produto" eager>
          <list-produto
            class="pa-4"
            :grupo="grupo"
            @cancelarEdicao="$emit('cancelarEdicao')"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosGrupo from './DadosGrupo.vue'
import ItemsMenu from './ItemsMenu.vue'
import ListTerminal from './ListTerminal.vue'
import ListProduto from './ListProduto.vue'

export default {
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },
  components: { DadosGrupo, ItemsMenu, ListTerminal, ListProduto },
  data() {
    return {
      tab: null,
    }
  },
}
</script>

<style></style>
