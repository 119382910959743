<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="grupo.nome"
            :rules="[() => !!grupo.nome || 'Esse campo é obrigatório!']"
            label="Nome"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" :disabled="!grupo.nome"/>
    </template>
  </base-modal>
</template>
<script>
import { mapActions } from 'vuex'
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'CadastroGrupo',
  props: {
    formTitle: String,
  },

  data() {
    return {
      grupo: {},
    }
  },

  methods: {
    ...mapActions('gerencial', ['addGrupo']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    close() {
      this.grupo = {}
      this.$emit('close')
    },

    async save() {
      try {
        await this.addGrupo(this.grupo)
        this.successMessage('Grupo adicionado com sucesso.')
      } catch (error) {
        this.errorMessage(error.response.data)
      }
      this.close()
    },
  },
}
</script>
