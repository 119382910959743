<template>
  <v-card class="mb-7">
    <validation-observer ref="observer">
      <v-form>
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <validation-provider
              v-slot="{ errors }"
              name="Nome"
              rules="required"
            >
              <v-text-field
                label="Nome"
                :error-messages="errors"
                v-model="grupo.nome"
                required
              ></v-text-field>
            </validation-provider>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <buttons-cadastro @close="reset" @save="atualizarNome" />
          </v-card-actions>
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import GrupoApi from '@/services/gerencial/grupo'
import { mapActions, mapState } from 'vuex'
import UtilsApi from '@/services/utils/utils.api'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  mixins: [validationMixin],

  components: { ButtonsCadastro },

  props: {
    value: {
      type: Object,
      required: true,
    },
    grupoNome: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      grupo: this.value,
      estados: [],
      grupos: [],
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  watch: {
    value(val) {
      Object.assign(this.grupo, val)
      this.$forceUpdate()
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    async getGrupos() {
      if (!this.empresaAtual.public_id) {
        this.errorMessage('Você não pertence a uma empresa')
        return
      }
      this.grupos = await GrupoApi.getGrupos(this.empresaAtual)
    },

    async atualizarNome() {
      try {
        await GrupoApi.setNome(this.grupo.public_id, this.grupo.nome)
        this.successMessage('Nome atualizado com sucesso')
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    reset() {
      this.$emit('cancelarEdicao')
    },
  },

  created() {
    this.getEstados(), this.getGrupos()
  },
}
</script>

<style>
</style>
