<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Grupos">
          <form-editar-grupo
            v-if="editarGrupo"
            @cancelarEdicao="cancelarEdicao"
            :grupo="grupoSelecionado"
          />
          <listar-grupos v-else @editar="editar" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarGrupos from '@/components/gerencial/grupos/ListarGrupos'
import FormEditarGrupo from '@/components/gerencial/grupos/editarGrupo/FormEditarGrupo'
export default {
  provide() {
    return {
      atualizaGrupo: (item) => {
        this.grupoSelecionado = item
      },
    }
  },
  data() {
    return {
      page: {
        title: 'Gerenciamento de grupos',
      },
      breadcrumbs: [
        {
          text: 'Gerencial',
          disabled: true,
        },
        {
          text: 'Grupos',
          disabled: false,
        },
      ],
      grupoSelecionado: {},
      editarGrupo: false,
    }
  },
  components: {
    ListarGrupos,
    FormEditarGrupo,
  },
  methods: {
    editar(grupo) {
      this.grupoSelecionado = grupo
      this.editarGrupo = true
    },
    cancelarEdicao() {
      this.grupoSelecionado = {}
      this.editarGrupo = false
    },
  },
}
</script>

<style></style>
