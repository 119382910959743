<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab href="#meusgrupos">Meus Grupos</v-tab>
      <v-tab v-if="empresaAtual.system_admin" href="#todososgrupos"
        >Todos os Grupos</v-tab
      >
      <v-tabs-items v-model="tabs">
        <v-tab-item value="meusgrupos">
          <div class="mt-4">
            <v-card-title v-if="canList">
              Filtro
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisa"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="grupos"
              :search="search"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Grupos registrados</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    title="Atualizar"
                    medium
                    @click="() => getGrupos()"
                    >mdi-refresh</v-icon
                  >
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="canCreate"
                        color="primary"
                        dark
                        class="mb-2"
                        v-on="on"
                        >Novo Grupo</v-btn
                      >
                    </template>
                    <cadastro-grupo :formTitle="formTitle" @close="close" />
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon v-if="canDelete" small @click="deleteItem(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <template #no-data v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </template>
            </v-data-table>
            <confirm-dialog @confirm="confirmDelete" v-if="canDelete" />
          </div>
        </v-tab-item>

        <v-tab-item value="todososgrupos">
          <div class="mt-4">
            <v-card-title v-if="canList">
              Filtro
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisa"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headersAllGrupos"
              :items="todosGrupos"
              :search="search"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Grupos registrados</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    title="Atualizar"
                    medium
                    @click="getAllGrupos"
                    >mdi-refresh</v-icon
                  >
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="canCreate"
                        color="primary"
                        dark
                        class="mb-2"
                        v-on="on"
                        >Novo Grupo</v-btn
                      >
                    </template>
                    <cadastro-grupo :formTitle="formTitle" @close="close" />
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon v-if="canDelete" small @click="deleteItem(item)"
                  >mdi-delete</v-icon
                >
                <confirm-dialog @confirm="confirmDelete" />
              </template>
              <template #no-data v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import CadastroGrupo from './CadastroGrupo.vue'
import ConfirmDialog from '@/components/template/ConfirmDialog'
export default {
  components: { CadastroGrupo, ConfirmDialog },
  data() {
    return {
      BasicPermissions,
      Sequences,
      tabs: null,
      search: '',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      headersAllGrupos: [
        { text: 'Nome', value: 'nome' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      grupoIndex: -1,
      grupo: {
        nome: '',
      },
      grupoAtual: {},
    }
  },
  computed: {
    ...mapState('gerencial', ['grupos', 'todosGrupos']),
    ...mapState('auth', ['empresaAtual']),
    
    permission() {
      return Sequences.Grupos.toString()
    },

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        this.permission
      )
    },
    canCreate() {
      return this.$canDo(
        BasicPermissions.CADASTRAR,
        this.permission
      )
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    formTitle() {
      return this.grupoIndex === -1 ? 'Adicionar novo grupo' : 'Editar grupo'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  async created() {
    if (this.canList) {
      await this.getGrupos()
      if (this.empresaAtual.system_admin) {
        await this.getAllGrupos()
      }
    }
  },
  methods: {
    ...mapActions('gerencial', ['getGrupos', 'getAllGrupos', 'delGrupo']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    editItem(item) {
      this.$emit('editar', item)
    },
    deleteItem(item) {
      this.grupoAtual = item
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: `Deseja mesmo excluir o grupo ${item.nome}?`,
      })
    },
    closeDeleteItem() {
      this.dialogDelete = false
    },
    async confirmDelete() {
      try {
        this.delGrupo(this.grupoAtual)
        this.successMessage('Grupo excluído com sucesso')
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
    close() {
      this.dialog = false
    },
  },
}
</script>