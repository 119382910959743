<template>
  <v-card class="pa-4">
    <v-card-subtitle class="text-center pt-2">
      Permissões para a tela de <strong>{{ item.title }}</strong>
    </v-card-subtitle>
    <v-card-text class="px-8">
      <v-simple-table dense class="text-left text-uppercase">
        <template v-slot:default>
          <tr v-for="ability in tenantItemMenuAbilities" :key="ability">
            <th>
              {{
                handleGetItemText(ability)
                  ? handleGetItemText(ability).replaceAll('_', ' ')
                  : ability
              }}
            </th>
            <td class="d-flex justify-center">
              <v-checkbox v-model="item.basicPermissions" :value="ability" />
            </td>
          </tr>
        </template>
      </v-simple-table>
      <p
        v-if="tenantItemMenuAbilities.length === 0"
        class="text-center error--text"
      >
        A empresa não possui permissões.
      </p>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn plain @click="$emit('cancelar')">Cancelar</v-btn>
      <v-btn
        color="secondary"
        @click="$emit('save', item.sequence, item.basicPermissions)"
        >Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { BasicPermissions, CustomPermissions } from '@/utils/permissions'
import { mapState } from 'vuex'

export default {
  name: 'ItemsMenuPermission',
  props: {
    itemMenu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      BasicPermissions,
      item: { ...this.itemMenu },
    }
  },
  computed: {
    ...mapState('abilities', ['tenantAbilities']),

    tenantItemMenuAbilities() {
      return (
        this.tenantAbilities.find((i) => i.subject == this.item.sequence)
          ?.action || []
      )
    },
  },
  methods: {
    getItemText(enumClass, item) {
      const index = Object.values(enumClass).findIndex((v) => v === item)
      return Object.keys(enumClass)[index]
    },
    handleGetItemText(item) {
      // try {
      const text = this.getItemText(BasicPermissions, item)
      if (text) {
        return text
      } else {
        return this.getItemText(CustomPermissions, item)
      }
      // } catch (error) {
      //   return "-"
      // }
    },
  },
}
</script>
